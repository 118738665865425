@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ebf3f3;
}
.results {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;

}

/* .result-hide {
  display: none;
} */

.libre {
  font-family: 'Libre Baskerville', serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.calc-box {
  justify-content: center;
}

.lines {
  position: absolute;
  right: 2px;
}
.btn {
  @apply font-bold py-2 px-4 rounded;
}
.btn-blue {
  @apply bg-blue-500 text-white;
}
.btn-blue:hover {
  @apply bg-blue-700;
}

.resultBox {
  background: #3E4B4B;
border-radius: 10px 10px 0px 10px;
}

/* remove up down arrow */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.alert-shown {
  color: red;
  opacity: 1;
  transition: all 750ms ;
}

/* .alert.slide-left {
  color: red;
  transform: translateX(-180px);
  left: 180px;
  position: relative;
  transition:  .5s;
} */

.alert-hidden {
  opacity: 0;
  transition: all 550ms linear;
}

@media only screen and (max-width: 770px) {
  .calc-box{
    display: block;
    text-align: center;
  }
  
  .label {
    text-align: left;
  }
   .input {
    width: auto;
    margin: 0;
  }

  .results {
    width: auto;
    padding: 0;
    margin-top: 2rem;
  }
}