/*Desktop View*/
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 40px;
    height: 80px;
  }
  .logo-nav {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 auto;
  }
  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logo {
    width: 55px;
    height: 55px;
  }
  .nav-options {
    padding-left: 25rem;
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 50px;
    list-style-type: none;
  }
  .mobile-option {
    display: none;
  }
  
  .option :hover {
    color: #3E4B4B;
  }
  .signin-up {
    display: flex;
    padding: 0px 5px;
    list-style-type: none;
  }
  .sign-in {
    padding-right: 50px;
    align-self: center;
  }
  .sign-in :hover {
    color: white;
  }
  .signup-btn {
    padding: 10px 10px;
    height: 2.4rem;
    border-radius: 3px;
    background: rgb(222, 9, 241);
    color: white;
    cursor: pointer;
    align-items: center;
  }
  .mobile-menu {
    display: none;
  }
  @media only screen and (max-width: 648px) {
    /*Mobile View */
    .header {
      padding: 0px 10px;
    }
    .logo {
      width: 45px;
      height: 45px;
    }
    .nav-options {
      display: flex;
      width: 100%;
      height: 350px;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 0;
      transition: all 0.5s ease;
      flex-direction: column;
      list-style-type: none;
      grid-gap: 0px;
    }
    .nav-options.active {
      background: #3E4B4B;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
      align-content: center;
      padding-left: 0px;
    }
  
    .menu-icon {
      width: 45px;
      height: 45px;
    }
    .option {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 10vw;
      padding: 30px 0px;
    }
    .sign-up {
      background: rgb(222, 9, 241);
      border-radius: 3px;
      color: white;
      padding: 20px 0px;
      width: 80%;
      align-self: center;
      border-radius: 3px;
    }
  
    .signin-up {
      display: none;
    }
  }
  .mobile-menu {
    display: block;
  }
  